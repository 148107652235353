import styled from 'styled-components';

const Container = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 80vh;

  & > div {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    text-align: center;
  }
`;

const Title = styled.h1`
  font-size: 1.75rem;
  font-weight: bold;
  margin-bottom: 1rem;
`;

const Description = styled.p`
  color: #7f7f7f;
  font-size: 1.1rem;
  margin-bottom: 2rem;
`;

const CTA = styled.a`
  text-decoration: none;
  font-weight: 600;
  font-size: 0.925rem;
  border: none;
  outline: none;
  background: #f26703;
  color: #fff;
  cursor: pointer;
  padding: 0.75rem 1.5rem;
  border-radius: 5rem;
`;

const S = {
  Container,
  Title,
  Description,
  CTA,
};

export default S;
