import { Breakpoints } from '@/src/types';
import styled from 'styled-components';

const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  padding: 3rem 0 6rem 0;
`;

const Header = styled.h1`
  font-weight: 400;
  font-size: 1.5rem;
  font-family: 'Bitter', serif;
  font-weight: bold;
  font-style: italic;
`;

const Images = styled.div`
  display: flex;
  margin-top: 0.5rem;

  & > img {
    margin: 0 1rem;
    object-fit: cover;
  }

  @media (max-width: ${Breakpoints.MOBILE}px) {
    flex-direction: column;

    & > img:not(:first-child) {
      margin-top: 2rem;
    }
  }
`;

const S = {
  Container,
  Header,
  Images,
};

export default S;
