import { IContactInfo, IFormWidget } from '../types';

const SMS_URL =
  process.env.NODE_ENV === 'development'
    ? 'http://localhost:3011/v0/sms'
    : 'https://campaign.cloud.staging.ysds.dev/v0/sms';

/**
 * Send a confirmation SMS to the user.
 * @param {IFormWidget} formData Form data for the user that made the request.
 * @param {IContactInfo} contactInfo The contact info of the YSDS operator.
 * @return {Promise<boolean>} Promise that resolves when the email is sent.
 */
export const sendConfirmationSMS = async (
  formData: IFormWidget,
  contactInfo: IContactInfo
): Promise<boolean> => {
  const data = {
    from: 'YSDS',
    to: formData.contact,
    message: 'Thank you - a representative will contact you shortly! /YSDS',
    dryrun: process.env.NODE_ENV === 'development' ? true : false,
  };

  return await fetch(SMS_URL, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify(data),
  })
    .then((res) => {
      if (res.ok) return true;

      return false;
    })
    .catch((err) => {
      return false;
    });
};

/**
 * Send a high urgency SMS to the YSDS operator.
 * @param {IContactInfo} contactInfo The contact info of the YSDS operator.
 * @return {Promise<boolean>} Promise that resolves when the email is sent.
 */
export const sendHighUrgencySMS = async (
  contactInfo: IContactInfo
): Promise<boolean> => {
  const data = {
    from: 'YSDSTech',
    to: contactInfo.phone,
    message:
      'You have a new customer that has an urgent need, please check your mail. /YSDS Tech',
    dryrun: process.env.NODE_ENV === 'development' ? true : false,
  };

  return await fetch(SMS_URL, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify(data),
  })
    .then((res) => {
      if (res.ok) return true;

      return false;
    })
    .catch((err) => {
      return false;
    });
};
