import { Breakpoints } from '@/src/types';
import styled from 'styled-components';

const Container = styled.div<{ colorTheme: { dark: string; light: string } }>`
  padding: 4rem 2rem;
  background: ${({ colorTheme }) => colorTheme.light};

  @media (min-width: ${Breakpoints.MOBILE}px) {
    padding: 4rem 10%;
  }

  @media (min-width: ${Breakpoints.DESKTOP}px) {
    padding: 4rem 22.5%;
  }
`;

const S = {
  Container,
};

export default S;
