import * as React from 'react';

import CtaButton, { ICtaButton } from '@/components/layout-blocks/CtaButton';
import Form, { IForm } from '@/components/layout-blocks/Form';
import InfoBlock, { IInfoBlock } from '@/components/layout-blocks/InfoBlock';
import MainHero, { IMainHero } from '@/components/layout-blocks/MainHero';
import NotFound, { INotFound } from '@/components/layout-blocks/NotFound';
import QualityCompliance, {
  IQualityCompliance,
} from '@/components/layout-blocks/QualityCompliance';
import Testimonials, {
  ITestimonials,
} from '@/components/layout-blocks/Testimonials';

export enum BlockType {
  CtaButton,
  Form,
  InfoBlock,
  MainHero,
  NotFound,
  QualityCompliance,
  Testimonials,
}

export type IComponentTypes =
  | typeof CtaButton
  | typeof Form
  | typeof MainHero
  | typeof NotFound
  | typeof InfoBlock
  | typeof QualityCompliance
  | typeof Testimonials;

export interface IComponent
  extends ICtaButton,
    IForm,
    INotFound,
    IMainHero,
    IInfoBlock,
    IQualityCompliance,
    ITestimonials {
  label: keyof typeof BlockType;
}
export interface IFlexible {
  components: ReadonlyArray<IComponent>;
  pageColor: string;
}

const BlockComponents: {
  [C in BlockType]: IComponentTypes;
} = {
  [BlockType.CtaButton]: CtaButton,
  [BlockType.Form]: Form,
  [BlockType.NotFound]: NotFound,
  [BlockType.MainHero]: MainHero,
  [BlockType.InfoBlock]: InfoBlock,
  [BlockType.QualityCompliance]: QualityCompliance,
  [BlockType.Testimonials]: Testimonials,
};

const Flexible: React.FC<IFlexible> = ({ components, pageColor }) => {
  return (
    <>
      {components
        ?.filter(
          (c) => c.label !== undefined && BlockType[c.label] !== undefined
        )
        .map((comp, index) => {
          const Component = BlockComponents[BlockType[comp.label]];

          return <Component key={index} {...comp} pageColor={pageColor} />;
        })}
    </>
  );
};

export default Flexible;
