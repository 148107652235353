import { Breakpoints } from '@/src/types';
import styled from 'styled-components';

const Container = styled.div<{
  imagePosition: 'left' | 'right';
  colorTheme: { dark: string; light: string };
}>`
  display: grid;
  grid-template-columns: repeat(2, minmax(0, 1fr));
  grid-gap: 0 2rem;
  align-items: center;
  border: 1px solid ${({ colorTheme }) => colorTheme.light};
  border-radius: 0.5rem;
  margin: 4rem 2rem;

  ${({ imagePosition }) =>
    imagePosition === 'left'
      ? `grid-template-areas: "image text";
        grid-template-columns: minmax(0, 5fr) minmax(0, 6fr);
        padding-right: 2rem;
        
        & > img {
          border-radius: 0.5rem 0 0 0.5rem; 
        }`
      : `grid-template-areas: "text image";
        grid-template-columns: minmax(0, 6fr) minmax(0, 5fr);
        padding-left: 2rem;
        
        & > img {
          border-radius: 0 0.5rem 0.5rem 0; 
        }`}

  & > img {
    height: 100%;
    width: 100%;
    grid-area: image;
    object-fit: cover;
  }

  & > div {
    padding: 2rem 0;
    width: 100%;
    grid-area: text;
  }

  @media (max-width: 900px) {
    display: flex;
    flex-direction: column;
    padding: 0;

    & > img {
      margin-bottom: 2rem;
      width: 100%;
      border-radius: 0.5rem 0.5rem 0 0;
    }

    & > div {
      margin-bottom: 2rem;
      padding: 0 2rem;
    }
  }

  @media (min-width: ${Breakpoints.MOBILE}px) {
    margin: 4rem 10%;
  }

  @media (min-width: ${Breakpoints.DESKTOP}px) {
    margin: 4rem 22.5%;
  }
`;

const Wrapper = styled.div<{ colorTheme: { dark: string; light: string } }>`
  margin: 4rem 2rem;
  border: 1px solid ${({ colorTheme }) => colorTheme.light};
  border-radius: 0.5rem;
  padding: 4rem 2rem;

  @media (min-width: ${Breakpoints.MOBILE}px) {
    margin: 4rem 10%;
  }

  @media (min-width: ${Breakpoints.DESKTOP}px) {
    margin: 4rem 22.5%;
  }
`;

const S = {
  Container,
  Wrapper,
};

export default S;
