import React from 'react';

import S from './CtaButton.style';
import { getPageColor } from '@/src/helpers';

export interface ICtaButton {
  text?: string;
  pageColor: string;
}

const CtaButton: React.FC<ICtaButton> = (data: ICtaButton) => {
  const colorTheme = getPageColor(data.pageColor);
  return (
    <S.Container>
      <S.Button colorTheme={colorTheme} href="#contact">
        {data.text || 'Get in touch!'}
      </S.Button>
    </S.Container>
  );
};

export default CtaButton;
