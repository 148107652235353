import { P } from '@/styles/typography';
import styled from 'styled-components';

const Container = styled.div<{ colorTheme: { dark: string; light: string } }>`
  position: fixed;
  z-index: 1000;
  bottom: 0;
  right: 0;
  left: 0;
  background-color: ${({ colorTheme }) => colorTheme.dark};
  color: white;
  display: grid;
  grid-gap: 0 2rem;
  grid-template-columns: 1fr 100px;
  grid-template-rows: auto;
  padding: 1.5rem 0.75rem;
  align-items: center;

  @media (min-width: 900px) {
    padding: 1.5rem 2rem;
  }

  & p {
    color: #fff;
  }
`;
const Paragraph = styled(P)`
  font-size: 0.925rem;
`;

const A = styled.a`
  margin-left: 0.5rem;
  font-size: 14px;
  color: white;
  text-decoration: underline;
  text-decoration-color: white;
  text-decoration-thickness: from-font;
  display: inline-block;
`;

const Button = styled.button`
  font-weight: 600;
  font-size: 0.925rem;
  border: none;
  outline: none;
  background: transparent;
  color: #fff;
  border: 2px solid #fff;
  cursor: pointer;
  padding: 0.5rem 1rem;
  border-radius: 5rem;
`;

const S = {
  Container,
  Paragraph,
  A,
  Button,
};

export default S;
