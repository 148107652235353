import styled from 'styled-components';

import { Breakpoints } from '@/src/types';

const Container = styled.div<{ colorTheme: { dark: string; light: string } }>`
  text-align: center;
  background: ${({ colorTheme }) => colorTheme.dark};
  padding: 4rem 2rem;

  @media (min-width: ${Breakpoints.MOBILE}px) {
    padding: 4rem 10%;
  }

  @media (min-width: ${Breakpoints.DESKTOP}px) {
    padding: 4rem 22.5%;
  }
`;

const Slide = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;

  & > p {
    font-size: 1.25rem;
    margin-bottom: 2rem;
    font-family: 'Bitter', serif;
    font-weight: bold;
    font-style: italic;
    margin: 1rem;
    color: #fff;

    @media (min-width: ${Breakpoints.MOBILE}px) {
      margin: 1rem 2rem;
    }
  }

  & > h3 {
    font-size: 1.05rem;
    color: #fff;
  }
`;

const Wrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
`;

const S = {
  Container,
  Slide,
  Wrapper,
};

export default S;
