import { IWPImage } from '@/src/types';
import React from 'react';

import S from './InfoBlock.style';
import { getPageColor } from '@/src/helpers';

export interface IInfoBlock {
  title?: string;
  text?: string;
  image?: IWPImage;
  imagePosition: 'left' | 'right';
  pageColor: string;
}

const InfoBlock: React.FC<IInfoBlock> = (data: IInfoBlock) => {
  const colorTheme = getPageColor(data.pageColor);

  // We don't use a WYSWYG editor here, so we need to format the text
  const formattedText = data.text?.split('\n').map((item, index) => {
    if (item.length <= 1) return <br key={index} />;
    return <p key={index}>{item}</p>;
  });

  if (!data.image)
    return (
      <S.Wrapper colorTheme={colorTheme}>
        <h2>{data.title || 'Title here'}</h2>
        {formattedText || (
          <p>
            Lorem ipsum dolor sit amet, consectetur adipiscing elit. Mauris
            aliquam, libero id rhoncus ornare, lacus diam luctus justo, quis
            sodales orci velit et orci. Nunc ornare bibendum imperdiet. Vivamus
            laoreet egestas nisl ac finibus. Etiam in erat libero.
          </p>
        )}
      </S.Wrapper>
    );

  return (
    <S.Container colorTheme={colorTheme} imagePosition={data.imagePosition}>
      <img src={data.image?.sourceUrl} alt={data.image?.altText} />
      <div>
        <h2>{data.title || 'Title here'}</h2>
        {formattedText || (
          <p>
            Lorem ipsum dolor sit amet, consectetur adipiscing elit. Mauris
            aliquam, libero id rhoncus ornare, lacus diam luctus justo, quis
            sodales orci velit et orci. Nunc ornare bibendum imperdiet. Vivamus
            laoreet egestas nisl ac finibus. Etiam in erat libero.
          </p>
        )}
      </div>
    </S.Container>
  );
};

export default InfoBlock;
