import { IWPImage } from '@/src/types';
import React from 'react';

import S from './QualityCompliance.style';

export interface IQualityCompliance {
  title?: string;
  images?: ReadonlyArray<{
    image?: IWPImage;
  }>;
  pageColor: string;
}

const QualityCompliance: React.FC<IQualityCompliance> = (
  data: IQualityCompliance
) => {
  return (
    <S.Container>
      <S.Header>{data.title || 'Quality Compliance'}</S.Header>
      <S.Images>
        {data.images &&
          data.images.map(({ image }, i) => (
            <img
              key={i}
              src={image?.sourceUrl}
              alt={image?.altText}
              width={150}
              height={150}
            />
          ))}
      </S.Images>
    </S.Container>
  );
};

export default QualityCompliance;
