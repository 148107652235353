import 'swiper/css';

import { IWPImage } from '@/src/types';
import React from 'react';
import { Swiper, SwiperSlide } from 'swiper/react';
import { Keyboard, Navigation, Pagination } from 'swiper';

import S from './Testimonials.styles';
import { getPageColor } from '@/src/helpers';

export interface ITestimonials {
  title?: string;
  testimonials: ReadonlyArray<{
    image?: IWPImage;
    name?: string;
    jobTitle?: string;
    testimonial?: string;
  }>;
  pageColor: string;
}

const Testimonials: React.FC<ITestimonials> = (data: ITestimonials) => {
  const colorTheme = getPageColor(data.pageColor);
  return (
    <S.Container colorTheme={colorTheme}>
      <S.Wrapper>
        <svg
          className="testimonial-prev-button"
          width="52"
          height="103.5"
          viewBox="0 0 104 207"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
          cursor={'pointer'}
        >
          <path
            d="M103.5 206L1.00001 103.5L103.5 1"
            stroke="white"
            strokeWidth="10"
          />
        </svg>
        <Swiper
          slidesPerView={1}
          loop
          navigation={{
            nextEl: '.testimonial-next-button',
            prevEl: '.testimonial-prev-button',
          }}
          keyboard={{ enabled: true }}
          pagination={{ clickable: true }}
          modules={[Keyboard, Pagination, Navigation]}
        >
          {data.testimonials &&
            data.testimonials.map(
              ({ image, name, jobTitle, testimonial }, i) => (
                <SwiperSlide key={i}>
                  <S.Slide>
                    <p>
                      {testimonial ||
                        `This is a testimonial. It tells other people why your product is superior to
                      other products and why YOU are the best company in the world! :)`}
                    </p>
                    <h3>
                      {name || 'Barack Obama'}
                      {(jobTitle && ' | ' + jobTitle) ||
                        ' | President of the United States'}
                    </h3>
                    {image && (
                      <img
                        src={image.sourceUrl}
                        alt={image.altText}
                        width={140}
                      />
                    )}
                  </S.Slide>
                </SwiperSlide>
              )
            )}
        </Swiper>
        <svg
          className="testimonial-next-button"
          width="52"
          height="103.5"
          viewBox="0 0 104 207"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
          cursor={'pointer'}
        >
          <path d="M1 1L103.5 103.5L1 206" stroke="white" strokeWidth="10" />
        </svg>
      </S.Wrapper>
    </S.Container>
  );
};

export default Testimonials;
