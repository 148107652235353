import { IWPImage } from '@/src/types';
import * as React from 'react';
import { Helmet } from 'react-helmet';

export interface SEOProps {
  title: string;
  metaDesc?: string;
  author?: string;
  ogTitle?: string;
  lang?: string;
  meta?: Array<{ name: string; content: string }>;
  canonical?: string;
  opengraphImage: IWPImage;
}

const Seo: React.FC<SEOProps> = ({
  title,
  metaDesc,
  author = 'Your Special Delivery Service',
  ogTitle,
  lang = 'en',
  meta = [],
  canonical,
  opengraphImage,
}: SEOProps) => {
  return (
    <Helmet
      htmlAttributes={{
        lang,
      }}
      title={title}
      link={[
        {
          rel: 'stylesheet',
          href: 'https://fonts.googleapis.com/css2?family=Bitter:ital,wght@1,300&display=swap',
        },
      ].concat()}
      meta={[
        {
          name: `description`,
          content: metaDesc,
        },
        {
          name: `author`,
          content: author,
        },
        {
          name: `robots`,
          content: 'noindex',
        },
        {
          name: `canonical`,
          content: canonical,
        },
        {
          property: `og:title`,
          content: title,
        },
        {
          property: `og:description`,
          content: metaDesc,
        },
        {
          property: `og:type`,
          content: `website`,
        },
        // {
        //   name: `og:image`,
        //   content: opengraphImage.sourceUrl,
        // },
        {
          name: `twitter:card`,
          content: `summary`,
        },
        {
          name: `twitter:creator`,
          content: author,
        },
        {
          name: `twitter:title`,
          content: title,
        },
        {
          name: `twitter:description`,
          content: metaDesc,
        },
        // {
        //   name: `twitter:image`,
        //   content: opengraphImage.sourceUrl,
        // },
      ].concat(meta)}
    />
  );
};

export default Seo;
