import { IWPImage } from '@/src/types';
import React from 'react';

import S from './MainHero.style';
import { getPageColor } from '@/src/helpers';

export interface IMainHero {
  title?: string;
  subtitle?: string;
  enabled: boolean;
  text?: string;
  image?: IWPImage;
  logo?: IWPImage;
  ctaText?: string;
  pageColor: string;
}

const MainHero: React.FC<IMainHero> = (data: IMainHero) => {
  const colorTheme = getPageColor(data.pageColor);
  return (
    <S.Container colorTheme={colorTheme}>
      {data.logo && (
        <a href="https://ysds.com" target={'_blank'} rel={'noreferrer'}>
          <img src={data.logo.sourceUrl} alt={data.logo.altText} height={38} />
        </a>
      )}
      <S.Wrapper colorTheme={colorTheme}>
        <div>
          <h1>{data.title || 'Title here'}</h1>
          {data.enabled && <h2>{data.subtitle || 'Subtitle here'}</h2>}
          <p>
            {data.text ||
              `Lorem ipsum dolor sit amet, consectetur adipiscing elit. Mauris aliquam, libero id
            rhoncus ornare, lacus diam luctus justo, quis sodales orci velit et orci. Nunc ornare
            bibendum imperdiet. Vivamus laoreet egestas nisl ac finibus. Etiam in erat libero.`}
          </p>
          <S.CTA href="#contact" colorTheme={colorTheme}>
            {data.ctaText || 'Get in touch!'}
          </S.CTA>
        </div>
        {data.image && (
          <img
            className="img"
            src={data.image.sourceUrl}
            alt={data.image.altText}
          />
        )}
      </S.Wrapper>
    </S.Container>
  );
};

export default MainHero;
