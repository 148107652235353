import styled from 'styled-components';

export const H1 = styled.h1`
  font-weight: 700;
`;

export const P = styled.p`
  font-size: 14px;
  margin: 0;
`;
