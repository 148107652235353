import React, { useEffect, useState } from 'react';

import PREFERENCES from './preferences';
import { IContactInfo, IFormWidget } from './types';
import { sendToGMP } from './utils/gmp';
import { sendConfirmationEmail, sendOperatorEmail } from './utils/mail';
import { sendConfirmationSMS } from './utils/sms';
import { uuid } from './utils/uuid';

import S from './FormWidget.style';

const setCookie = (): void => {
  const cookies = document.cookie.split(';');
  const cookie = cookies.find((c) => c.includes('tracking_cid'));

  if (cookie) return;

  document.cookie = `tracking_cid=${uuid()}; path=/; max-age=31536000`;
};

type Props = {
  colorTheme: {
    light: string;
    dark: string;
  };
};

export const FormWidget: React.FC<Props> = ({ colorTheme }) => {
  const [submitted, setSubmitted] = useState(false);
  const [cardFlipped, setCardFlipped] = useState(false);
  const [input, setInput] = useState({} as IFormWidget);
  const [contactInfo, setContactInfo] = useState<IContactInfo>(
    {} as IContactInfo
  );

  const {
    COUNTRIES,
    DEFAULT_EMAIL,
    DEFAULT_CONTACT_NAME,
    DEFAULT_CONTACT_PHONE,
  } = PREFERENCES;

  useEffect(() => setCookie(), []);

  useEffect(() => {
    COUNTRIES.forEach((country) => {
      if (input.country !== country.name) return;

      if (!country.industry) {
        return setContactInfo({
          name: DEFAULT_CONTACT_NAME,
          email: DEFAULT_EMAIL,
          phone: DEFAULT_CONTACT_PHONE,
        });
      }

      Object.entries(country.industry).forEach(([industry, contact]) => {
        // if (input.industry !== industry) return;

        return setContactInfo({
          name: contact.name,
          email: contact.email,
          phone: contact.phone,
        });
      });
    });
  }, [input.country]);

  const handleSubmit = async (e: React.SyntheticEvent) => {
    e.preventDefault();

    // This should not be able to happen, but just in case
    if (Object.values(input).some((o) => !o || o === ''))
      return alert('Please fill out all fields.');

    // Sanitize contact input
    if (!input.contact.match(/[A-Za-z]/g) && !input.contact.includes('@')) {
      if (!input.contact.match(/\+\d{1,3}[ ]{0,1}\d{6,14}$/g))
        return alert(
          'Ooops! Your phone number need to include the country code and be no longer than 14 digits (ie. +46 1231231234)'
        );
    } else {
      if (!input.contact.match(/(\S+)@(\S+)\.(\S+)/g))
        return alert('Please enter a valid email address.');
    }

    setSubmitted(true);

    const gmpStatus = await sendToGMP(input);
    if (!gmpStatus) {
      alert(
        'Something went wrong when sending the request! Please try again at a later time.'
      );

      return setSubmitted(false);
    }

    // We always emails to the sales rep
    await sendOperatorEmail(input, contactInfo);

    // Send email or SMS to the customer
    if (input.contact.includes('@')) {
      await sendConfirmationEmail(input, contactInfo);
    } else {
      await sendConfirmationSMS(input, contactInfo);
    }

    // // If the urgency is high, the operator should also get a SMS
    // if (input.urgency === 'high') await sendHighUrgencySMS(contactInfo);

    setTimeout(() => {
      setCardFlipped(true);
      location.hash = '#contact';
    }, Math.floor(Math.random() * 1000));
  };

  if (cardFlipped)
    return (
      <S.Container colorTheme={colorTheme}>
        <h1>Thank you!</h1>
        <div>
          <p>
            If urgent, please visit our website to find our office&apos;s
            contact details:{' '}
            <a href="www.ysds.com/offices">www.ysds.com/offices</a>
          </p>
          <p>
            Otherwise, a YSDS logistics expert will reach out to you shortly.
          </p>
        </div>
      </S.Container>
    );

  return (
    <S.Container colorTheme={colorTheme}>
      <div>
        <h2>Let us help you!</h2>
        <p>
          We care for your product as if it was our own. Fill out the form for a
          free consultation and let us show you how we can support.
        </p>
      </div>

      <form onSubmit={(e) => handleSubmit(e)}>
        <S.Label>
          <S.Input
            colorTheme={colorTheme}
            name="name"
            type="text"
            required
            placeholder="Name"
            onChange={(e) =>
              setInput({ ...input, name: e.currentTarget.value })
            }
          />
        </S.Label>
        <S.Label>
          <S.Input
            colorTheme={colorTheme}
            name="company"
            type="text"
            required
            placeholder="Company"
            onChange={(e) =>
              setInput({ ...input, company: e.currentTarget.value })
            }
          />
        </S.Label>
        {/* <S.Label>
          <S.Select
            name="industry"
            required
            defaultValue={''}
            onChange={(e) =>
              setInput({
                ...input,
                industry: e.currentTarget.value as IndustryType,
              })
            }
          >
            <option disabled value="">
              Choose industry
            </option>
            <option>Life Science</option>
            <option>Specialty</option>
            <option>Art</option>
            <option>Other</option>
          </S.Select>
        </S.Label> */}

        <S.Label>
          <S.Input
            colorTheme={colorTheme}
            name="contact"
            type="text"
            required
            placeholder="Phone or email"
            onChange={(e) =>
              setInput({ ...input, contact: e.currentTarget.value })
            }
          />
        </S.Label>
        {/* <S.Label>
          <S.Select
            name="urgency"
            required
            defaultValue={''}
            onChange={(e) =>
              setInput({ ...input, w: e.currentTarget.value })
            }
          >
            <option disabled value="">
              How urgent is this?
            </option>
            <option value={'high'}>Very - need help now</option>
            <option value={'medium'}>I am planning shipping</option>
            <option value={'low'}>Want general information</option>
          </S.Select>
        </S.Label> */}

        <S.Label>
          <S.Select
            colorTheme={colorTheme}
            name="country"
            required
            defaultValue={''}
            onChange={(e) =>
              setInput({ ...input, country: e.currentTarget.value })
            }
          >
            <option value="" disabled>
              Choose country
            </option>
            {COUNTRIES.map(({ code, name }, index) => (
              <option key={`${index}-${code}`}>{name}</option>
            ))}
          </S.Select>
        </S.Label>
        <div />
        {input.country && contactInfo.name ? (
          <S.Text>
            A YSDS logistics expert will contact as soon as possible once
            you&apos;ve completed the form.
          </S.Text>
        ) : null}
        <S.Button colorTheme={colorTheme} disabled={submitted}>
          Send
        </S.Button>
      </form>
    </S.Container>
  );
};
