import { IFormWidget } from '../types';
import { uuid } from './uuid';

const GMP_URL =
  process.env.NODE_ENV === 'development'
    ? 'http://localhost:3030/v0/gmp'
    : 'https://campaign.cloud.staging.ysds.dev/v0/gmp';

/**
 * Sends a transaction event to Google Measurement Protocol.
 * @param {IFormWidget} formData Form data for the user that made the request.
 */
export const sendToGMP = async (formData: IFormWidget): Promise<boolean> => {
  // Sends virtual pageview to Google Analytics. This is be the broke af on Firefox DE, but should
  // work on Chrome + Safari + normal Firefox. If this changes, this will need to be proxied to
  // our campaign service, as to not get descrepencies between the GA and GMP data.
  // WGP wants this.
  process.env.NODE_ENV !== 'development'
    ? ga('send', {
        hitType: 'pageview',
        page: '/why-thank-you.html',
        title: `why - ${formData.country}`,
      })
    : null;

  const version = 1;
  const hitType = 'pageview';
  const tid = 'UA-47562353-4';
  const tr = 100;
  const ti = Math.random().toString().substring(2, 20);
  const cid =
    document.cookie
      .split(';')
      .find((c) => c.includes('tracking_cid'))
      ?.split('=')[1] || uuid();

  const body = {
    v: version,
    t: hitType,
    tid: tid,
    cid: cid,
    ti: ti,
    tr: tr,
    userAgent: navigator.userAgent,
    dh: 'why.ysds.com',
    dp: '/thanks',
    dt: 'ContactThanks',
    ta: 'CampaignSite',
    tt: 0,
    ts: 0,
    pa: 'purchase',
    pr1id: 'ContactForm',
    pr1br: 'YSDS',
    pr1ps: 'Bottom',
    pr1nm: `${formData.country}`,
  };

  return await fetch(GMP_URL, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify(body),
  })
    .then((res) => {
      if (res.ok) return true;

      return false;
    })
    .catch((err) => {
      return false;
    });
};
