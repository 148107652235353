import { IContactInfo, IFormWidget } from '../types';

const MAIL_URL =
  process.env.NODE_ENV === 'development'
    ? 'http://localhost:3030/v0/mail'
    : 'https://campaign.cloud.staging.ysds.dev/v0/mail';

/**
 * Send a confirmation email to the user.
 * @param {IFormWidget} formData Form data for the user that made the request.
 * @param {IContactInfo} contactInfo The contact info of the YSDS operator.
 * @return {Promise<boolean>} Promise that resolves when the email is sent.
 */
export const sendConfirmationEmail = async (
  formData: IFormWidget,
  contactInfo: IContactInfo
): Promise<boolean> => {
  const message = `<p>Thank you - a representative will contact you shortly! /YSDS</p>`;

  const data = {
    from: 'it@ysds.com',
    to: formData.contact,
    subject: 'Contact request confirmation',
    html: message,
  };

  return await fetch(MAIL_URL, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify(data),
  })
    .then((res) => {
      if (res.ok) return true;

      return false;
    })
    .catch((err) => {
      return false;
    });
};

/**
 * Send an email to the YSDS operator.
 * @param {IFormWidget} formData Form data for the user that made the request.
 * @param {IContactInfo} contactInfo The contact info of the YSDS operator.
 * @return {Promise<boolean>} Promise that resolves when the email is sent.
 */
export const sendOperatorEmail = async (
  formData: IFormWidget,
  contactInfo: IContactInfo
): Promise<boolean> => {
  // let priorityMessage = 'priority';
  // switch (formData.urgency) {
  //   case 'high':
  //     priorityMessage = 'they need help now (high priority)';
  //     break;
  //   case 'medium':
  //     priorityMessage = 'they are planning a shipment (medium priority)';
  //     break;
  //   case 'low':
  //     priorityMessage = 'they want general information (low priority)';
  //     break;
  // }

  const message = `<p>On ${new Date().toLocaleString()} - <b>"${
    formData.name
  }, ${formData.company} (${
    formData.country
  })"</b> completed the contact form on why.ysds.com. Please contact the party at <a href="mailto:${
    formData.contact
  }">${formData.contact}</a>.</p>

  <p>Regards,</p>
  <b>YSDS Tech</b>`;

  const data = {
    from: 'it@ysds.com',
    to: contactInfo.email,
    // cc: 'it@ysds.com',
    subject: 'New online customer!',
    html: message,
  };

  return await fetch(MAIL_URL, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify(data),
  })
    .then((res) => {
      if (res.ok) return true;

      return false;
    })
    .catch((err) => {
      return false;
    });
};
