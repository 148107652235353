import { IWPImage } from '@/src/types';
import { graphql, useStaticQuery } from 'gatsby';

export type IFooterData = {
  wp: {
    siteSettings: {
      footer: {
        footerLogo?: IWPImage;
        footerColor?: string;
      };
    };
  };
};

const useFooterData = () => {
  const { wp }: IFooterData = useStaticQuery(
    graphql`
      {
        wp {
          siteSettings {
            footer {
              footerLogo {
                sourceUrl
                altText
              }
              footerColor
            }
          }
        }
      }
    `
  );
  return wp.siteSettings.footer;
};
export default useFooterData;
