import { Breakpoints } from '@/src/types';
import styled from 'styled-components';

const Container = styled.div<{ colorTheme: { dark: string; light: string } }>`
  display: flex;
  justify-content: center;
  background: ${({ colorTheme }) => colorTheme.dark};
  padding: 4rem 2rem 3.5rem 2rem;
  display: flex;
  flex-direction: column;
  position: relative;
  margin-bottom: 5rem;

  & > a {
    margin-bottom: 3.5rem;
    text-decoration: none;
  }

  & h1 {
    font-size: 2.75rem;
    color: #fff;
  }

  & p {
    color: #fff;
    font-size: 1.75rem;
    font-family: 'HelveticaBold';
  }

  @media (min-width: ${Breakpoints.MOBILE}px) {
    padding: 4rem 10%;
  }

  @media (min-width: ${Breakpoints.DESKTOP}px) {
    padding: 4rem 22.5%;
  }
`;

const Wrapper = styled.div<{ colorTheme: { dark: string; light: string } }>`
  display: flex;
  flex-direction: column-reverse;

  & > div {
    margin-right: 5rem;
  }

  & .img {
    width: 100%;
    border: 1px solid ${({ colorTheme }) => colorTheme.light};
    margin-bottom: 3rem;
  }

  @media (min-width: 900px) {
    display: grid;
    grid-template-columns: minmax(0, 4fr) minmax(0, 3fr);

    & .img {
      margin: 0;
    }
  }
`;

const CTA = styled.a<{ colorTheme: { dark: string; light: string } }>`
  display: inline-block;
  margin-top: 3rem;
  font-size: 1.4rem;
  font-family: 'HelveticaBold', sans-serif;
  font-weight: 700;
  border: none;
  outline: none;
  background: ${({ colorTheme }) => colorTheme.light};
  box-shadow: 0 0 10px 0 rgba(100, 100, 100, 0.25);
  color: ${({ colorTheme }) => colorTheme.dark};
  cursor: pointer;
  padding: 1rem 2.5rem;
  border-radius: 5rem;
  text-decoration: none;

  position: absolute;
  bottom: -1.75rem;
`;

const S = {
  Container,
  Wrapper,
  CTA,
};

export default S;
