import styled from 'styled-components';

const Container = styled.div<{ colorTheme: { dark: string; light: string } }>`
  background: #fff;
  border-radius: 0.5rem;
  padding: 2.5rem 2rem;
  box-shadow: 0 0 10px 0 rgba(100, 100, 100, 0.25);
  display: grid;
  grid-template-columns: minmax(0, 1fr) minmax(0, 2fr);
  grid-gap: 0 3rem;
  margin: 1rem 0;

  @media (max-width: 900px) {
    grid-template-columns: minmax(0, 1fr);
    grid-gap: 2rem 0;
  }

  & > form {
    display: grid;
    grid-template-columns: repeat(2, minmax(0, 1fr));
    grid-gap: 1.5rem 1rem;
  }

  & > h1 {
    color: #000;
    font-size: 2rem;
  }

  & > p {
    color: #000;
  }

  & p:not(:last-child) {
    margin-bottom: 0.5rem;
  }

  @media (max-width: 600px) {
    & > form {
      display: flex;
      flex-direction: column;
    }
  }

  a {
    color: ${({ colorTheme }) => colorTheme.dark};
    text-decoration: none;
    font-weight: bold;
  }
`;

const Bold = styled.p`
  font-weight: bold;
  margin-top: 0.5rem;
  font-family: 'HelveticaBold', sans-serif;
  opacity: 0.85;
`;

const Label = styled.label`
  display: flex;
  flex-direction: column;
  color: #000;
  font-size: 0.925rem;
`;

const Input = styled.input<{ colorTheme: { dark: string; light: string } }>`
  border: none;
  outline: none;
  font-size: 1rem;
  padding: 0.35rem 0.25rem;
  margin-top: 0.5rem;
  color: ${({ colorTheme }) => colorTheme.dark};
  border-bottom: 1px solid ${({ colorTheme }) => colorTheme.light};
  transition: all 0.2s ease-in-out;

  &::placeholder {
    color: ${({ colorTheme }) => colorTheme.dark};
    opacity: 1;
  }
`;

const Select = styled.select<{ colorTheme: { dark: string; light: string } }>`
  margin-top: 0.5rem;
  font-size: 1rem;
  padding: 0.35rem 0.15rem;
  appearance: none;
  background: transparent;
  border: none;
  outline: none;
  color: ${({ colorTheme }) => colorTheme.dark};
  border-bottom: 1px solid ${({ colorTheme }) => colorTheme.light};

  background: url("data:image/svg+xml;utf8,<svg xmlns='http://www.w3.org/2000/svg' width='100' height='100' fill='2308758a'><polygon points='0,0 100,0 50,50'/></svg>")
    no-repeat;

  background-size: 12px;
  background-position: calc(100% - 20px) center;
  background-repeat: no-repeat;
`;

const Button = styled.button<{ colorTheme: { dark: string; light: string } }>`
  margin: 1rem auto 0 auto;
  font-size: 1.1rem;
  border: none;
  outline: none;
  background: ${({ colorTheme }) => colorTheme.dark};
  color: #fff;
  cursor: pointer;
  padding: 1.5rem 3.25rem;
  border-radius: 5rem;
  text-decoration: none;
  height: 44px;
  place-self: self-end;
  display: flex;
  justify-content: center;
  align-items: center;
  font-family: 'HelveticaBold', sans-serif;
  grid-column: 1 / span 2;

  &:disabled {
    background: ${({ colorTheme }) => colorTheme.dark};
    opacity: 0.5;
    cursor: not-allowed;
  }
`;

const Text = styled.p`
  display: inline-block;
  text-align: center;
  color: gray;

  grid-column: 1 / span 2;
`;

const S = {
  Container,
  Bold,
  Label,
  Input,
  Select,
  Button,
  Text,
};

export default S;
