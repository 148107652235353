import { Breakpoints } from '@/src/types';
import styled from 'styled-components';

const Container = styled.div<{ colorTheme: { dark: string; light: string } }>`
  background: ${({ colorTheme }) => colorTheme.dark};
  padding: 4rem 2rem;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 2rem 0;

  @media (min-width: ${Breakpoints.MOBILE}px) {
    display: grid;
    grid-template-columns: repeat(3, minmax(0, 1fr));
    grid-gap: 0 1rem;
  }

  & img {
    max-width: 100%;
  }

  & p {
    color: #fff;
    font-size: 0.9rem;
    opacity: 1;
    font-family: 'HelveticaBold', sans-serif;
  }

  & a {
    color: #fff;
    font-size: 0.9rem;
    text-decoration: none;
    display: block;
    opacity: 0.9;
  }

  & .social {
    display: flex;

    p {
      margin: 0.75rem 0 0 0.5rem;
    }
  }

  & svg {
    margin-top: 0.5rem;
    display: inline;
    width: 2rem;
    height: 2rem;
  }

  & a:not(:last-child) {
    margin-bottom: 0.5rem;
  }

  @media (min-width: ${Breakpoints.MOBILE}px) {
    padding: 4rem 10%;
  }

  @media (min-width: ${Breakpoints.DESKTOP}px) {
    padding: 4rem 22.5%;
  }
`;

const S = {
  Container,
};

export default S;
