import * as React from 'react';
import { ThemeProvider } from 'styled-components';

import Body from '@/components/core-blocks/Body';
import CookieConsent from '@/components/core-blocks/CookieConsent';

import Footer from '@/components/core-blocks/Footer';
// import Header from '@/components/core-blocks/Header';

import Main from '@/components/core-blocks/Main';
import Seo, { SEOProps } from '@/components/core-blocks/Seo';
import Flexible, { IFlexible } from '@/src/components/core-blocks/Flexible';
import GlobalStyle from '@/styles/global';
import defaultTheme from '@/styles/themes/default.theme';

export type IPageTemplate = {
  pageContext: {
    flexible: IFlexible;
    pageColor: {
      pageColor: string;
    };
    slug: string;
    seo: SEOProps;
  };
};

const PageTemplate: React.FC<IPageTemplate> = ({ pageContext }) => {
  const pageColor = pageContext.pageColor.pageColor;

  return (
    <ThemeProvider theme={defaultTheme}>
      <GlobalStyle />
      <Seo {...pageContext.seo} />
      <Body>
        {/* <Header /> */}
        <Main>
          <Flexible
            components={pageContext.flexible.components}
            pageColor={pageColor}
          />
          <CookieConsent pageColor={pageColor} />
        </Main>
        <Footer pageColor={pageColor} linkOverride={pageContext.slug as any} />
      </Body>
    </ThemeProvider>
  );
};

export default PageTemplate;
