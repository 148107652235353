import React from 'react';

import S from './NotFound.style';

export interface INotFound {
  title?: string;
  description?: string;
  cta?: string;
  ctaLink?: string;
}

const NotFound: React.FC<INotFound> = (data: INotFound) => {
  return (
    <S.Container>
      <div>
        <S.Title>{data.title || '404 - Page not found'}</S.Title>
        <S.Description>
          {data.description ||
            `Oh no, something went wrong! Please try again later or contact the site
            administrator if the problem persist`}
        </S.Description>
        <S.CTA href={data.ctaLink || '/'}>{data.cta || 'Back to home'}</S.CTA>
      </div>
    </S.Container>
  );
};

export default NotFound;
