import { graphql, useStaticQuery } from 'gatsby';

export type ICookieConsentData = {
  paragraph: string;
  label: string;
  link: {
    title: string;
    target: string;
    url: string;
  };
  color?: string;
};

const useCookieConsentData = (): ICookieConsentData => {
  const data = useStaticQuery(graphql`
    {
      wp {
        siteSettings {
          cookie {
            cc {
              paragraph
              label
              link {
                target
                title
                url
              }
              color
            }
          }
        }
      }
    }
  `);

  return data.wp.siteSettings.cookie.cc;
};

export default useCookieConsentData;
