import React from 'react';

export const nlToBr = (text: string) =>
  (text || '').split('\n').map((l, index) => (
    <React.Fragment key={index}>
      {l}
      <br />
    </React.Fragment>
  ));

export const getPageColor = (pageColor: string) => {
  switch (pageColor) {
    case 'art':
      return { dark: '#853761', light: '#d8afc5' };
    case 'lifeScience':
      return { dark: '#08758A', light: '#aed6df' };
    case 'specialtyLogistics':
      return { dark: '#0A8D5E', light: '#94e1c5' };
    default:
      return { dark: '#08758A', light: '#aed6df' };
  }
};
