export type IWPImage = {
  sourceUrl: string;
  altText: string;
  mimeType?: string;
};

export type IWPLink = {
  title: string;
  target: string;
  url: string;
};

export enum Breakpoints {
  MOBILE = '700',
  TABLET = '1200',
  DESKTOP = '1400',
}
