import styled from 'styled-components';

const Container = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 4rem 2rem;
`;

const Button = styled.a<{ colorTheme: { dark: string; light: string } }>`
  display: inline-block;
  font-size: 1.25rem;
  border: none;
  outline: none;
  background: transparent;
  color: ${({ colorTheme }) => colorTheme.dark};
  border: 2px solid ${({ colorTheme }) => colorTheme.dark};
  cursor: pointer;
  padding: 1rem 3.25rem;
  border-radius: 5rem;
  text-decoration: none;
  display: flex;
  justify-content: center;
  align-items: center;
  font-family: 'HelveticaBold', sans-serif;

  &:hover {
    background: ${({ colorTheme }) => colorTheme.dark};
    color: #fff;
  }
`;

const S = {
  Container,
  Button,
};

export default S;
