import Cookies from 'js-cookie';
import React, { useEffect, useState } from 'react';

import S from './CookieConsent.style';
import useCookieConsentData from './useCookieConsentData';
import { getPageColor } from '@/src/helpers';

type Props = {
  pageColor: string;
};

const CookieConsent: React.FC<Props> = ({ pageColor }) => {
  const cookieConsent = useCookieConsentData();
  const [isOpen, setIsOpen] = useState(true);
  const cookieValue = Cookies.get('cookieConsent');

  const colorTheme = getPageColor(pageColor);

  useEffect(() => setIsOpen(cookieValue !== 'true'), [cookieValue]);
  const enableAnalytics = () => {
    Cookies.set('cookieConsent', 'true'),
      {
        sameSite: 'lax',
        secure: 'true',
        expires: 365,
      };
    setIsOpen(false);
  };

  return isOpen ? (
    <S.Container colorTheme={colorTheme}>
      {cookieConsent.paragraph && (
        <S.Paragraph>
          {cookieConsent.paragraph}
          <S.A href={cookieConsent.link.url} target="_blank" rel="noopener">
            {cookieConsent.link.title}
          </S.A>
        </S.Paragraph>
      )}
      <S.Button onClick={enableAnalytics}>{cookieConsent.label}</S.Button>
    </S.Container>
  ) : null;
};

export default CookieConsent;
