import * as React from 'react';

import S from './Footer.style';
import useFooterData from './useFooterData';
import { getPageColor } from '@/src/helpers';

type Props = {
  pageColor: string;
  linkOverride: 'specialtylogistics' | 'lifescience' | 'art';
};

const Footer: React.FC<Props> = ({ pageColor, linkOverride }) => {
  const data = useFooterData();

  const colorTheme = getPageColor(pageColor);
  return (
    <S.Container colorTheme={colorTheme}>
      {data.footerLogo && (
        <img
          src={data.footerLogo.sourceUrl}
          alt={data.footerLogo.altText}
          width={225}
        />
      )}
      <div>
        {linkOverride === 'specialtylogistics' && (
          <a href="https://ysds.com/it-2" target="_blank" rel="noreferrer">
            <p>YSDS Specialty Logistics</p>
          </a>
        )}
        {linkOverride === 'lifescience' && (
          <a
            href="https://ysds.com/lifescience/"
            target="_blank"
            rel="noreferrer"
          >
            <p>YSDS Life Science</p>
          </a>
        )}
        {linkOverride === 'art' && (
          <a href="https://ysds.com/art/" target="_blank" rel="noreferrer">
            <p>YSDS Art</p>
          </a>
        )}
        <a href="https://ysds.com/offices" target="_blank" rel="noreferrer">
          <p>Our offices</p>
        </a>
        {/* <a href="https://ysds.com/contact" target="_blank" rel="noreferrer">
          <p>Contact us</p>
        </a> */}
      </div>
      <div className="social">
        <div>
          <a href="https://www.linkedin.com/company/ysds-uniquelogisticssolutions/mycompany/">
            <svg
              aria-label="LinkedIn Logo"
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 24 24"
              fill="currentColor"
            >
              <path d="M19 0h-14c-2.761 0-5 2.239-5 5v14c0 2.761 2.239 5 5 5h14c2.762 0 5-2.239 5-5v-14c0-2.761-2.238-5-5-5zm-11 19h-3v-11h3v11zm-1.5-12.268c-.966 0-1.75-.79-1.75-1.764s.784-1.764 1.75-1.764 1.75.79 1.75 1.764-.783 1.764-1.75 1.764zm13.5 12.268h-3v-5.604c0-3.368-4-3.113-4 0v5.604h-3v-11h3v1.765c1.396-2.586 7-2.777 7 2.476v6.759z"></path>
            </svg>
          </a>
        </div>
        <p>Follow us on LinkedIn</p>
        {/* <a href="#">Bing bong länk här</a>
        <a href="#">Bing bong länk här</a> */}
      </div>
    </S.Container>
  );
};

export default Footer;
