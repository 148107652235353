import React from 'react';

import { FormWidget } from '@/components/react';

import S from './Form.style';
import { getPageColor } from '@/src/helpers';

export interface IForm {
  title?: string;
  pageColor: string;
}

const Form: React.FC<IForm> = (data: IForm) => {
  const colorTheme = getPageColor(data.pageColor);
  return (
    <S.Container colorTheme={colorTheme} id="contact">
      <FormWidget colorTheme={colorTheme} />
    </S.Container>
  );
};

export default Form;
